.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-around;
  border-radius: "15px";
  align-items: center;
  box-shadow: -15px -15px 15px rgba(255, 255, 255, 0.2), 15px 15px 15px rgba(0, 0, 0, 0.1);
}

.cp-text {
  color: rgba(0, 0, 0, 0.7);
  text-shadow: 0 1px rgba(255, 255, 255, 0.1);
}
